import axios from "axios";
import {
  USERS_API,
  USERS_LIST_API,
  USERS_STAT_API,
  PASSWORD_RESET_API,
  PASSWORD_SEND_API,
} from "../config";

/**
 * Permet d'enregistrer un nouvel utilisateur.
 */
function create(user) {
  return axios.post(USERS_API, user);
}

/**
 *
 * @param {int} id
 * @param {array} user
 */
function update(id, user) {
  return axios.put(USERS_API + "/" + id, user);
}

/**
 * Récupère les informations des utilisateurs.
 */
function fetch() {
  return axios.get(USERS_API).then((response) => response.data["hydra:member"]);
}

/**
 * Récupère la liste des utilisateurs.
 * Limité à l'utilisateur si ROLE_USER. Si ROLE_ADMIN, accès à tous les users.
 */
function getUsersList() {
  return axios.get(USERS_LIST_API).then((response) => response.data);
}

/**
 * Permet de récupérer des informations sur l'utilisateur.
 */
function getInfo() {
  return axios.get(USERS_API).then((response) => response.data["hydra:member"]);
}

/**
 * Permet de récupérer des statistiques sur l'utilisateur.
 */
function getStat(id) {
  return axios.get(USERS_STAT_API + "/" + id).then((response) => response.data);
}

/**
 * PasswordReseter: Envoie l'email pour génération du token.
 */
function passwordReset(email) {
  return axios
    .post(PASSWORD_RESET_API, email)
    .then((response) => response.data);
}

/**
 * Envoie le nouveau mot de passe
 */
function passwordSending(data) {
  return axios.post(PASSWORD_SEND_API, data).then((response) => response.data);
}

const exportedObject = {
  create,
  getInfo,
  fetch,
  getUsersList,
  update,
  getStat,
  passwordReset,
  passwordSending,
};

export default exportedObject;
