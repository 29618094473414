import React from 'react';
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Button, useMediaQuery, Divider } from '@material-ui/core';
import Section from "../components/organisms/Section";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    all: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    global: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)`,
        maxWidth: "98%",
        margin: `0 auto`,
    },
    section: {
        '& .section-alternate__content': {
            paddingTop: 0,
            marginTop: theme.spacing(-5),
            position: 'relative',
            zIndex: 1,
        },
        '& .card-base__content': {
            padding: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
    },
    info: {
        backgroundColor: "#D2EBF5",
        padding: "8px",
        border: "0.1rem solid",
        borderColor: "#C8E7F3",
        marginTop: "20px",
        marginBottom: "20px",
    },
    title: {
        marginBottom: "30px",
    },
    logo: {
        marginRight: "6px",
    },
    buttonContainer: {
        marginTop: "1vh",
    },
    marginButton: {
        marginLeft: "1vw",
    }
}));

const RegisterSuccessPage = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <>


            <Section className={classes.all}>
                <div className={classes.global}>
                    <Grid container display="column" justifyContent="flex-start">
                        <Typography variant={isMd ? "h1" : "h2"} color="primary" className={classes.title}>Inscription réussie</Typography>
                        <Typography variant={isMd ? "h6" : "body1"} align="justify">Nous avons bien enregistré votre inscription. Activez maintenant votre compte en suivant le lien dans l'email que nous vous avons envoyé.</Typography>
                        <Grid container display="row" className={classes.info}>
                            <InfoIcon color="primary" className={classes.logo} />
                            <Typography variant={isMd ? "body1" : "body2"}>Vous n'avez pas reçu d'email? Vérifiez vos courriers indésirables.</Typography>
                        </Grid>
                        <Divider />
                        <Grid container display="row" className={classes.buttonContainer}>
                            <Link to="/login"><Button variant="outlined" color="primary" size="large" >Se connecter</Button></Link>
                            <Link to="/#"><Button variant="contained" color="primary" size="large" className={classes.marginButton}>Retour</Button></Link>
                        </Grid>

                    </Grid>
                </div>
            </Section>
        </>
    );
}

export default RegisterSuccessPage;