import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Container,
  Hidden,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import SideDrawer from "./SideDrawer";
import authAPI from "../../Services/authAPI";
import AuthContext from "../../contexts/AuthContext";
import { NavLink } from "react-router-dom";
import Dropdown from "../atoms/Dropdown";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    zIndex: 999,
    width: "100%",
    margin: "0 auto",
    padding: theme.spacing(0, 2),
    backgroundColor: "#FFF",
  },
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
    paddingRight: "4",
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoContainer: {
    width: 100,
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
    marginRight: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  logo: {
    width: 100,
    height: 28,
    color: `#3F51B5`,
    fontWeight: "600",
    textTransform: `uppercase`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `#2d3748`,
  },
  listItem: {
    cursor: "pointer",
    "&:hover > .menu-item, &:hover svg": {
      color: theme.palette.primary.dark,
    },
    "&.menu-item--no-dropdown": {
      paddingRight: 0,
    },
  },
}));

const Navbar = ({ history }) => {
  const classes = useStyles();

  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const { role, setRole } = useContext(AuthContext);
  const { userName } = useContext(AuthContext);

  const handleLogout = () => {
    authAPI.logout();
    setIsAuthenticated(false);
    setRole("none");
    history.push("/login");
  };

  const mediumSize = useMediaQuery("(min-width:1320px)");

  return (
    <>
      <AppBar position="static" elevation={1}>
        <Toolbar className={classes.toolbar}>
          <Container maxWidth="xl" className={classes.navbarDisplayFlex}>
            <div className={classes.logoContainer}>
              <NavLink to={isAuthenticated ? "/front" : "/home"}>
                <Typography
                  variant="h4"
                  gutterBottom={false}
                  className={classes.logo}
                >
                  {" "}
                  MedClick
                </Typography>
              </NavLink>
            </div>

            <Hidden mdDown>
              <List>
                {isAuthenticated ? (
                  <div className={classes.navigationContainer}>
                    <div className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <Dropdown
                          buttonText="Interventions"
                          firstOption="Ajouter"
                          firstPath="/surgeries/new"
                          secondOption="Mes interventions"
                          secondPath="/surgeries"
                          thirdOption="Mes favoris"
                          thirdPath="/favorites"
                        />
                      </ListItem>
                    </div>

                    <div className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <Dropdown
                          buttonText="Consultations"
                          firstOption="Ajouter"
                          firstPath="/consultations/new"
                          secondOption="Mes consultations"
                          secondPath="/consultations"
                        />
                      </ListItem>
                    </div>

                    <div className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <Dropdown
                          buttonText="Gardes"
                          firstOption="Ajouter"
                          firstPath="/gardes/new"
                          secondOption="Mes gardes"
                          secondPath="/gardes"
                        />
                      </ListItem>
                    </div>

                    <div className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <Dropdown
                          buttonText="Formations"
                          firstOption="Ajouter"
                          firstPath="/formations/new"
                          secondOption="Mes Evènement"
                          secondPath="/formations"
                        />
                      </ListItem>
                    </div>

                    <NavLink to="/signup" className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <NavLink
                          to="/years"
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          <Button variant="outlined">Mes années</Button>
                        </NavLink>
                      </ListItem>
                    </NavLink>

                    <NavLink to="/login">
                      <ListItem>
                        <Button
                          variant="contained"
                          color="primary"
                          target="blank"
                          onClick={handleLogout}
                          size={mediumSize ? "medium" : "small"}
                        >
                          Se déconnecter
                        </Button>
                      </ListItem>
                    </NavLink>
                  </div>
                ) : (
                  <div className={classes.navigationContainer}>
                    <NavLink to="/register" className={classes.linkText}>
                      <ListItem button className={classes.listItem}>
                        <Button variant="outlined">S'enregistrer</Button>
                      </ListItem>
                    </NavLink>

                    <NavLink to="/login">
                      <ListItem>
                        <Button
                          variant="contained"
                          color="primary"
                          target="blank"
                        >
                          Se connecter
                        </Button>
                      </ListItem>
                    </NavLink>
                  </div>
                )}
              </List>
            </Hidden>

            <Hidden lgUp>
              <SideDrawer
                isAuthenticated={isAuthenticated}
                role={role}
                userName={userName}
                logout={handleLogout}
              />
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
