import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    width: "100%",
  },
}));

const CustomSelect = ({
  name,
  placeHolder,
  value,
  onChange,
  children,
  emptyValue = false,
  error = false,
  textHelper,
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={error}
    >
      <InputLabel id="demo-simple-select-outlined-label">
        {placeHolder}
      </InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={value}
        onChange={onChange}
        className={classes.selectEmpty}
        label={placeHolder}
        name={name}
        disabled={disabled}
      >
        {emptyValue ?? (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}

        {children}
      </Select>
      <FormHelperText>{textHelper}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
