import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Button,
  Typography,
  Container,
  Divider,
  Hidden,
} from "@material-ui/core";
import Image from "../components/atoms/Image";
import SectionHeader from "../components/molecules/SectionHeader";
import Section from "../components/organisms/Section";
import Pc from "../images/pc.png";
import Smartphone from "../images/smartphone.png";
import About from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionAlternate from "../components/organisms/SectionAlternate";
import Accordion from "../components/organisms/Accordion";
import ConditionDialog from "../components/molecules/ConditionDialog";

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  LandingImageTwo: {
    width: 550,
    height: 750,
    position: "absolute",
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: 180,
      height: 250,
      right: "20vw",
    },
  },
  marginButton: {
    marginLeft: "1vw",
  },
  about: {
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  aboutImage: {
    width: "45vw",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  list: {
    listStyle: "none",
    paddingLeft: 100,
    paddingRight: 150,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 20,
    },
  },
  liStyle: {
    marginBottom: 20,
    borderBottom: "1px solid #ebebeb",
  },
  pstyle: {
    paddingLeft: 60,
  },
  sectionAlternate: {
    background: "transparent",
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
  bottom: {
    backgroundColor: "#343537",
    color: "#fff",
  },
  bottomContainer: {
    maxWidth: 600,
  },
  divider: {
    marginTop: "2vh",
    marginBottom: "4vh",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  useEffect(() => {
    AOS.init();
  }, []);

  const faq = [
    {
      id: "faq-1",
      title: "L'application Medclick",
      subtitle: "Comment puis-je installer l’application ?",
      text: "Medclick utilise une technologie appelée progressive web app. C’est-à-dire que tu peux la télécharger sans passer par un store. Pour cela rens toi dans les options de ton navigateur et clique sur ajouter à l’écran d’accueil. L’application se téléchargera. À noter que sur les smartphones Android, l’option s’affichera automatiquement lorsque tu arrives sur le site.",
    },
    {
      id: "faq-2",
      title: "Est-ce payant?",
      subtitle: "Ou cela le deviendra-t-il ?",
      text: "Nous n'avons pas encore décidé du moyen de financement de l'application (frais de serveur, dévelopeur, designer, ...). Tant que l'application reste dans sa version bêta, elle sera gratuite.",
    },
    {
      id: "faq-3",
      title: "Et si l'application devenait payante?",
      subtitle: "Pourrais-je toujours accéder à mon profil?",
      text: "Vous aurez toujours accès de manière gratuite aux informations encodées dans la version bêta.",
    },
    {
      id: "faq-4",
      title: "Informations renseignées.",
      subtitle: "Que faites-vous des informations que j'enregistre?",
      text: "Rien ! Elles ne servent qu'à l'élaboration de vos statistiques et ne seront jamais partagées. Seul toi y as accès! (Voir conditions générales).",
    },
    {
      id: "faq-5",
      title: "Mise à jour",
      subtitle: "Comment puis-je vous/nous aider?",
      text: "N'hésite pas à nous contacter pour rapporter un bug ou proposer une idée afin d'améliorer l'application.",
    },
  ];

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  // Gestion du dialog:

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Section className={classes.pagePaddingTop}>
        <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={6} data-aos={"fade-up"}>
            <SectionHeader
              title={
                <span>
                  <Typography variant={"h1"} color="primary">
                    Medclick
                  </Typography>
                  <Hidden smDown>
                    L'application web{" "}
                    <Typography
                      component="span"
                      variant="inherit"
                      color="primary"
                    >
                      smart medical.
                    </Typography>
                    <br />
                  </Hidden>
                </span>
              }
              subtitle="Solution pour médecin en formation"
              ctaGroup={[
                <>
                  <Link to="/login">
                    <Button
                      variant="contained"
                      color="primary"
                      size={isMd ? "large" : "medium"}
                    >
                      Se connecter
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button
                      variant="outlined"
                      color="primary"
                      size={isMd ? "large" : "medium"}
                      className={classes.marginButton}
                    >
                      S'enregistrer
                    </Button>
                  </Link>
                </>,
              ]}
              align={isMd ? "left" : "center"}
              disableGutter
              titleVariant="h3"
            />
            <Divider color="primary" className={classes.divider} />
            {
              //<Button size={/>/isMd ? "large" : "medium"} color="primary" fullWidth="true" variant="outlined">Rapide INAMI</Button>
            }
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            md={6}
          >
            <div className={classes.image}>
              <Image
                src={Pc}
                alt="TheFront Company"
                className={classes.LandingImageOne}
              />
              <Hidden mdDown>
                <Image
                  src={Smartphone}
                  alt="TheFront Company"
                  className={classes.LandingImageTwo}
                />
              </Hidden>
            </div>
          </Grid>
        </Grid>
      </Section>

      <Section name="about" className={classes.about}>
        <Grid container item={12}>
          <Grid
            container
            item
            xs={12}
            md={4}
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={About} className={classes.aboutImage} />
          </Grid>

          <Grid container item xs={12} md={8}>
            <div
              className="aboutText"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <Typography variant="h3">Qu'est-ce que</Typography>
              <Typography variant="h3" color="primary">
                MEDCLICK?
              </Typography>
            </div>

            <div className="aboutList">
              <ol className={classes.list}>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  className={classes.liStyle}
                >
                  <Typography variant="h4" color="primary">
                    01
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.pstyle}
                    align="justify"
                  >
                    Une solution créée par des assistants pour des assistants,
                    afin d'apporter une solution pour la tenue de ton "carnet de
                    stage".
                  </Typography>
                </li>

                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  className={classes.liStyle}
                >
                  <Typography variant="h4" color="primary">
                    02
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.pstyle}
                    align="justify"
                  >
                    Un moyen de rechercher plus facilement les codes INAMI des
                    interventions que tu réalises.
                  </Typography>
                </li>

                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  className={classes.liStyle}
                >
                  <Typography variant="h4" color="primary">
                    03
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.pstyle}
                    align="justify"
                  >
                    Un outils capable de générer automatiquement ton carnet de
                    stage au format Excel. Ainsi, il te sera toujours possible
                    de le modifier.
                  </Typography>
                </li>

                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  className={classes.liStyle}
                >
                  <Typography variant="h4" color="primary">
                    04
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.pstyle}
                    align="justify"
                  >
                    Une application qui te permet de suivre ton évolution au
                    jour le jour.
                  </Typography>
                </li>
              </ol>
            </div>
          </Grid>
        </Grid>
      </Section>

      <SectionAlternate
        name="faq"
        innerNarrowed
        className={classes.sectionAlternate}
      >
        <SectionHeader
          title="FAQ"
          subtitle="Consulte notre FAQ pour répondre à tes questions."
          fadeUp
        ></SectionHeader>
        <Accordion items={faq}></Accordion>
      </SectionAlternate>

      <Section className={classes.bottom}>
        <Container className={classes.bottomContainer}>
          <Grid
            container
            justify="space-between"
            direction={isMd ? "row" : "column"}
            spacing={isMd ? 4 : 2}
            alignContent="center"
          >
            <a
              href="mailto:medclick.info@gmail.com"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              <Typography> Nous contacter</Typography>
            </a>
            <Typography
              onClick={() => handleClickOpen()}
              style={{ cursor: "pointer" }}
            >
              Conditions d'utilisation
            </Typography>

            <Typography> Version: beta 2.3</Typography>
          </Grid>
        </Container>
      </Section>

      <ConditionDialog openDialog={openDialog} handleClose={handleClose} />
    </>
  );
};

export default HomePage;
