import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Grid,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Divider from "@material-ui/core/Divider";
import logo from "../../images/icon-192x192.png";

const SideDrawer = ({ navLinks, isAuthenticated, role, userName, logout }) => {
  const useStyles = makeStyles((theme) => ({
    list: {
      width: 250,
      height: "100%",
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `black`,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    containerDisplay: {
      height: "100vh",
    },
    bottonDisplay: {
      marginBottom: "2vh",
    },
    logo: {
      width: "40px",
      height: "40px",
    },
    LogoContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: "2vh",
      marginTop: "1vh",
    },
  }));

  const classes = useStyles();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ [anchor]: open });
  };

  const closeSideBar = () => {
    setState({ right: false });
  };

  const sideDrawerList = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="flex-start"
        className={classes.containerDisplay}
      >
        <List component="nav">
          <Container className={classes.LogoContainer}>
            <img src={logo} alt="Responsive " className={classes.logo} />
          </Container>

          <Divider variant="middle" />

          <div className={classes.linkText}>
            {isAuthenticated && (
              <div>
                <ListItem
                  button
                  onClick={() =>
                    setOpen1(!open1) &
                    setOpen2(false) &
                    setOpen3(false) &
                    setOpen4(false)
                  }
                >
                  <ListItemText primary="Interventions" />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      to="/surgeries/new"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={closeSideBar}
                      >
                        <ListItemText primary="Ajouter" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/surgeries"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Mes interventions" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/favorites"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Favoris" />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={() =>
                    setOpen2(!open2) &
                    setOpen1(false) &
                    setOpen3(false) &
                    setOpen4(false)
                  }
                >
                  <ListItemText primary="Consultations" />
                  {open2 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open2} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      to="/consultations/new"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Ajouter" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/consultations"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Mes consultations" />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={() =>
                    setOpen3(!open3) &
                    setOpen1(false) &
                    setOpen2(false) &
                    setOpen4(false)
                  }
                >
                  <ListItemText primary="Gardes" />
                  {open3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      to="/gardes/new"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Ajouter" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/gardes"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Mes gardes" />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={() =>
                    setOpen4(!open4) &
                    setOpen1(false) &
                    setOpen2(false) &
                    setOpen3(false)
                  }
                >
                  <ListItemText primary="Formations" />
                  {open4 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open4} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <NavLink
                      to="/formations/new"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Ajouter" />
                      </ListItem>
                    </NavLink>

                    <NavLink
                      to="/formations"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemText primary="Mes évènements" />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>

                <NavLink
                  to="/years"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <ListItem>
                    <ListItemText primary="Mes années" />
                  </ListItem>
                </NavLink>
              </div>
            )}

            {!isAuthenticated && (
              <div>
                <NavLink to="/login" onClick={toggleDrawer(anchor, false)}>
                  <ListItem>
                    <ListItemText primary="Se connecter" />
                  </ListItem>
                </NavLink>
              </div>
            )}

            {!isAuthenticated && (
              <NavLink
                to="/register"
                style={{ color: "black", textDecoration: "none" }}
              >
                <ListItem>
                  <ListItemText primary="S'enregistrer" />
                </ListItem>
              </NavLink>
            )}

            {isAuthenticated && (
              <ListItem>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Se déconnecter" onClick={logout} />
              </ListItem>
            )}
          </div>
        </List>

        {isAuthenticated && (
          <ListItem className={classes.bottonDisplay}>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                userName.firstname.toUpperCase() +
                " " +
                userName.lastname.toUpperCase()
              }
              secondary="Adresse email"
            />
          </ListItem>
        )}
      </Grid>
    </div>
  );

  return (
    <>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <MenuIcon fontSize="large" style={{ color: `#2d3748` }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer("right", true)}
        onClose={toggleDrawer("right", false)}
      >
        {sideDrawerList("right")}
      </Drawer>
    </>
  );
};

export default SideDrawer;
