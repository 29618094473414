import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';

export default function ScrollDialog({ openDialog, handleClose }) {

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog]);

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Conditions d'utilisation</DialogTitle>
                <DialogContent dividers={'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Typography variant="body1">Données personnelles. Respect du Règlement Général de Protection des Données (GDPR).</Typography><br />

                        <Typography variant="subtitle1">a) Utilisations des données:</Typography>

                        <Typography variant="body2" align="justify">Toutes les données personnelles fournies par le visiteur ou membre sont destinées à l'usage interne de l’application MED-CLICK. Nous nous engageons  à utiliser exclusivement les données qui sont indispensables à une qualité de service optimale.</Typography><br />

                        <Typography variant="subtitle1">b) Informations conllectées:</Typography>

                        <Typography variant="body2" align="justify">Lors de votre visite sur le site <span>www.medclick.be</span>, nous sommes susceptibles de collecter les informations suivantes :<br />
                            - votre 'domain name' (adresse IP) ;<br />
                            - votre adresse e-mail lorsque vous envoyez des messages/questions sur le site ou lorsque vous communiquez avec MED-CLICK  par e-mail ;<br />
                            - l'ensemble de l'information concernant les pages que vous avez consultées sur le site de MED-CLICK ;<br />
                            - toute information que vous nous avez donnée volontairement (par exemple dans le cadre d'enquêtes d'informations et/ou des inscriptions sur site).<br /><br />
                            Ces informations sont utilisées pour :<br />
                            -  l’application des algorithmes de notre site web ;<br />
                            - améliorer le contenu de notre site web ;<br />
                            - personnaliser le contenu et le lay-out de nos pages pour chaque visiteur individuel ;<br />
                            - vous informer des mises à jour de notre site ;<br />
                            - vous aviser d’informations utiles si nécessaire ;<br />
                            - vous contacter ultérieurement à des fins de marketing direct.</Typography><br />

                        <Typography variant="subtitle1">c) Cookies et sécurité:</Typography>

                        <Typography variant="body2" align="justify">Le site <span>www.medclick.be</span> utilise des cookies, à savoir de petits fichiers déposés sur le disque dur de votre ordinateur qui conservent des informations en vue d'une connexion ultérieure.
                            Ces cookies permettent à MED-CLICK d’enregistrer vos préférences et d’améliorer ou accélérer vos prochaines visites du site.
                            MED-CLICK utilise des technologies de cryptage qui sont reconnues comme les standards industriels dans le secteur, quand elle collecte et utilise vos données personnelles.</Typography><br />

                        <Typography variant="subtitle1">d) Moyens de communication:</Typography>

                        <Typography variant="body2" align="justify"> Si vous nous communiquez votre adresse e-mail via le web, vous pouvez recevoir des e-mails de notre société afin de vous communiquer des informations sur nos produits, services ou événements à venir (dans un but de marketing direct), à la condition que vous y ayez expressément consenti ou que vous soyez déjà membre chez nous et que vous nous ayez communiqué votre adresse e-mail.
                            Si vous ne souhaitez plus recevoir de tels e-mails, vous pouvez en faire la demande de plusieurs manières possibles :<br />
                            - soit cliqué sur le lien de désinscription qui se trouve en bas de chaque e-mail de promotion ou marketing.<br />
                            - soit envoyez-nous un e-mail à <span>medclick.info@gmail.be</span></Typography><br />

                        <Typography variant="subtitle1">e) Nouvelles utilisations</Typography>

                        <Typography variant="body2" align="justify"> MED-CLICK pourrait utiliser les informations des visiteurs ou clients pour de nouvelles utilisations qui ne sont pas encore prévues dans la politique « données personnelles ».
                            Dans cette hypothèse, MED-CLICK  contactera préalablement les membres concernés et leur offrira la possibilité de refuser de participer à ces nouveaux usages.</Typography><br />

                        <Typography variant="subtitle1">f) Droit d’information, de correction et droit à l’oubli.</Typography>

                        <Typography variant="body2" align="justify">Sur requête, nous procurons aux visiteurs de notre site un accès à toutes les informations les concernant.
                            Par ailleurs, conformément au Règlement européen de protection des données (GDPR), tout visiteur ou membre peut obtenir gratuitement la rectification, la limitation, la suppression ou l’interdiction d’utilisation de toute donnée à caractère personnel le concernant.
                            Si le visiteur ou membre souhaite introduire une telle demande, merci d’envoyer un e-mail à <span>medclick.info@gmail.be</span>.
                            MED-CLICK s’engage à traiter votre demande dans un délai d’un mois.
                            Si vous estimez que notre site ne respecte pas notre police vie privée telle qu'elle est décrite, veuillez prendre contact avec :<br />
                            - MED-CLICK, dont les coordonnées sont reprises ci-dessus.</Typography><br />


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Retour
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}