export const API_URL = "https://www.api-link.medclick.be/api/";

//export const API_URL = "http://localhost:8000/api/";

export const SURGERIES_API = API_URL + "surgeries";

export const USERS_API = API_URL + "users";

export const USERS_LIST_API = API_URL + "userslist";

export const USERS_STAT_API = API_URL + "userStat";

export const YEARS_API = API_URL + "years";

export const SURGEONS_API = API_URL + "surgeons";

export const SURGEONS_LIST_API = API_URL + "list";

export const NOMENCLATURE_API = API_URL + "nomenclature";

export const CONSULTATION_API = API_URL + "consultations";

export const LOGGIN_API = API_URL + "login_check";

export const FAVORITES_API = API_URL + "favorites";

export const FORMATIONS_API = API_URL + "formations";

export const GARDES_API = API_URL + "gardes";

export const STATISTICS_API = API_URL + "statistics";

export const EXCEL_API = API_URL + "excel";

export const PASSWORD_RESET_API = API_URL + "forgottenPassword";

export const PASSWORD_SEND_API = API_URL + "resetPassword";
