import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

import userAPI from "../Services/userAPI";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Button,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Field from "../components/atoms/Field";
import Select from "../components/atoms/CustomSelect";
import MenuItem from "@material-ui/core/MenuItem";

import Section from "../components/organisms/Section";
import SectionHeader from "../components/molecules/SectionHeader";
import LearnMoreLink from "../components/atoms/LearnMoreLink";
import ConditionDialog from "../components/molecules/ConditionDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  all: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  global: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    "& .section-alternate__content": {
      paddingTop: 0,
      marginTop: theme.spacing(-5),
      position: "relative",
      zIndex: 1,
    },
    "& .card-base__content": {
      padding: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(3),
      },
    },
  },
  menu: {
    height: "auto",
  },
  list: {
    display: "inline-flex",
    overflow: "auto",
    flexWrap: "nowrap",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      marginRight: theme.spacing(-3),
      marginLeft: theme.spacing(-3),
    },
  },
  listItem: {
    marginRight: theme.spacing(2),
    flex: 0,
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      borderLeft: "2px solid transparent",
    },
  },
  listItemActive: {
    [theme.breakpoints.up("md")]: {
      borderLeft: `2px solid ${theme.palette.primary.dark}`,
    },
    "& .menu__item": {
      color: theme.palette.text.primary,
    },
  },

  formItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },

  test: {
    width: "100%",
  },
  marginButton: {
    marginLeft: "1vw",
  },
}));

const RegisterPage = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const { loading, setLoading } = useContext(AuthContext);

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    speciality: "",
    password: "",
    passwordConfirm: "",
  });

  const [check, setCheck] = useState(false);

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    speciality: "",
    password: "",
    passwordConfirm: "",
  });

  const specialityLinks = [
    { value: `dig`, title: `Chirurgie digestive` },
    { value: `general`, title: `Chirurgie générale` },
    { value: `ortho`, title: `Chirurgie orthopédique` },
    { value: `plastic`, title: `Chirurgie plastique` },
    { value: `thor`, title: `Chirurgie thoracique` },
    { value: `uro`, title: `Chirurgie urologique` },
    { value: `vasc`, title: `Chirurgie vasculaire` },
  ];

  //Gestion des champs
  const handleChange = ({ currentTarget }) => {
    const value = currentTarget.value;
    const name = currentTarget.name;

    setUser({ ...user, [name]: value });
  };

  const selectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setUser({ ...user, [name]: value });
  };

  const switchChange = (event) => {
    setCheck(event.target.checked);
  };

  // Gestion du dialog:

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const apiErrors = {};
    if (user.password !== user.passwordConfirm) {
      apiErrors.passwordConfirm = "Les mots de passe renseignés sont diffèrent";
      setErrors(apiErrors);
      setLoading(false);
      return;
    }

    try {
      console.log(user);
      setLoading(true);
      const response = await userAPI.create(user);
      setErrors({});
      setLoading(false);
      history.replace("/registerSuccess");
    } catch (error) {
      setLoading(false);
      console.log(error.response);

      const { violations } = error.response.data;

      if (violations) {
        violations.forEach((violation) => {
          apiErrors[violation.propertyPath] = violation.message;
        });
        setErrors(apiErrors);
        setLoading(false);
      }
    }
  };

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <Section className={classes.all}>
        <div className={classes.global}>
          <SectionHeader
            title="S'enregistrer"
            subtitle="Gérer son carnet de stage rapidement et en toute simplicité."
            titleProps={{
              variant: "h3",
            }}
          />

          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="firstname"
                  label="Prénom *"
                  value={capitalize(user.firstname)}
                  placeholder="Quel est votre prénom"
                  justRead={false}
                  error={errors.firstname ? true : false}
                  helperText={errors.firstname}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  name="lastname"
                  label="Nom *"
                  value={capitalize(user.lastname)}
                  placeholder="Nom de famille"
                  justRead={false}
                  error={errors.lastname ? true : false}
                  helperText={errors.lastname}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="email"
                  label="Email *"
                  value={user.email}
                  placeholder="Votre adresse email"
                  justRead={false}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Select
                  name="speciality"
                  placeHolder="Filière de formation *"
                  value={user.speciality}
                  error={errors.speciality ? true : false}
                  textHelper={errors.speciality}
                  emptyValue={false}
                  onChange={selectChange}
                >
                  {specialityLinks.map(({ value, title }) => (
                    <MenuItem value={value} key={value}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="password"
                  type="password"
                  label="Mot de passe *"
                  value={user.password}
                  placeholder="Mot de passe"
                  justRead={false}
                  autoComplete="new-password"
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="passwordConfirm"
                  type="password"
                  label="Confirmation du mot de passe *"
                  value={user.passwordConfirm}
                  placeholder="Confirmer le mot de passe"
                  justRead={false}
                  error={errors.passwordConfirm ? true : false}
                  helperText={errors.passwordConfirm}
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>

              <Grid item xs={12}>
                <i>
                  <Typography variant="subtitle2">
                    Les champs marqués d'une * sont obligatoire.
                  </Typography>
                </i>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  display="row"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onChange={switchChange}
                        color="primary"
                      />
                    }
                  />
                  <Typography>J'accepte les</Typography>
                  <Button color="primary" onClick={handleClickOpen}>
                    conditions générales
                  </Button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  onClick={handleSubmit}
                  disabled={loading ? true : false}
                  fullWidth
                  disabled={check ? false : true}
                >
                  Enregistrer
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  align="center"
                >
                  Déjà un compte?{" "}
                  <Link to="/login">
                    <LearnMoreLink title="Se connecter" />
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>

        <ConditionDialog openDialog={openDialog} handleClose={handleClose} />
      </Section>
    </>
  );
};

export default RegisterPage;
