import { TextField } from '@material-ui/core';
import React from 'react';


const Field = ({ name, label, value, onChange, placeholder = "", type = "text", error = false, helperText, onClick, justRead = false, autoComplete = "" }) => (
        <TextField
                placeholder={placeholder}
                label={label}
                variant="outlined"
                size="medium"
                name={name}
                fullWidth
                onChange={onChange}
                type={type}
                value={value}
                error={error}
                helperText={error ? helperText : null}
                onClick={onClick}
                autoComplete={autoComplete}
                InputProps={{
                        readOnly: justRead,
                }}
        />
);



export default Field;