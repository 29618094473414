import React, { useState, lazy, Suspense } from "react";
import {
  HashRouter,
  Route,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";

import AuthContext from "./js/contexts/AuthContext";
import authAPI from "./js/Services/authAPI";
import PrivateRoute from "./js/components/PrivateRoute";
import AdminRoute from "./js/components/AdminRoute";

import Navbar from "./js/components/Navbar/Navbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import "./App.css";

import HomePage from "./js/Pages/HomePage";
import LoginPage from "./js/Pages/LoginPage";
import RegisterPage from "./js/Pages/RegisterPage";
import RegisterSuccessPage from "./js/Pages/RegisterSuccessPage";
//const ForgottenPasswordPage = lazy(() =>
//  import("./js/Pages/ForgottenPasswordPage")
//);
//const ResetPasswordPage = lazy(() => import("./js/Pages/ResetPasswordPage"));
//const PasswordSuccessPage = lazy(() =>
//  import("./js/Pages/PasswordSuccessPage")
//);
const PasswordEmailPage = lazy(() => import("./js/Pages/PasswordEmailPage"));
const SurgeriesPage = lazy(() => import("./js/Pages/SurgeriesPage"));
const SurgeriePage = lazy(() => import("./js/Pages/surgeriePage/SurgeryPage"));
const ConsultationsPage = lazy(() => import("./js/Pages/ConsultationsPage"));
const ConsultationPage = lazy(() => import("./js/Pages/ConsultationPage"));
const GardesPage = lazy(() => import("./js/Pages/GardesPage"));
const GardePage = lazy(() => import("./js/Pages/GardePage"));
const FormationsPage = lazy(() => import("./js/Pages/FormationsPage"));
const FormationPage = lazy(() => import("./js/Pages/FormationPage"));
const FavoritesPage = lazy(() => import("./js/Pages/FavoritesPage"));
const FavoritePage = lazy(() => import("./js/Pages/FavoritePage"));
const YearsPage = lazy(() => import("./js/Pages/YearsPage"));
const YearPage = lazy(() => import("./js/Pages/YearPage"));
const SurgeonsPage = lazy(() => import("./js/Pages/SurgeonsPage"));
const SurgeonPage = lazy(() => import("./js/Pages/SurgeonPage"));
const FrontPage = lazy(() => import("./js/Pages/FrontPage"));
const TestPage = lazy(() => import("./js/Pages/surgeriePage/SurgeryPage"));

authAPI.setup();

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authAPI.isAuthenticated()
  );
  const [userName, setUserName] = useState(authAPI.getIdentity());
  const [role, setRole] = useState(authAPI.getRole());
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState();

  const history = useHistory();

  const NavbarWithRouter = withRouter(Navbar);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userName,
        setUserName,
        role,
        setRole,
        loading,
        setLoading,
        years,
        setYears,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <HashRouter>
          <main>
            <Suspense fallback={() => setLoading(true)}>
              <NavbarWithRouter />
              {loading && <LinearProgress />}

              <Switch>
                <PrivateRoute
                  path="/front"
                  component={FrontPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/surgeries/:id"
                  component={SurgeriePage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/surgeries/:id"
                  component={SurgeriePage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/surgeries"
                  component={SurgeriesPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/consultations/:id"
                  component={ConsultationPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/consultations"
                  component={ConsultationsPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/gardes/:id"
                  component={GardePage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/gardes"
                  component={GardesPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/formations/:id"
                  component={FormationPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/formations"
                  component={FormationsPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/favorites/:id"
                  component={FavoritePage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/favorites"
                  component={FavoritesPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/years/:id"
                  component={YearPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/years"
                  component={YearsPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/surgeons/:id"
                  component={SurgeonsPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/surgeon/:id"
                  component={SurgeonPage}
                  theme={theme}
                />

                <PrivateRoute
                  path="/test/:id"
                  component={TestPage}
                  theme={theme}
                />

                <Route
                  path="/register"
                  component={RegisterPage}
                  theme={theme}
                />

                <Route
                  path="/registerSuccess"
                  component={RegisterSuccessPage}
                  theme={theme}
                />

                <Route path="/login" component={LoginPage} theme={theme} />

                <Route path="/" component={HomePage} theme={theme} />
              </Switch>
            </Suspense>
          </main>
        </HashRouter>
      </MuiThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
