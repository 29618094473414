import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import authAPI from "../Services/authAPI";
import AuthContext from "../contexts/AuthContext";

import { Button, Grid, Typography } from "@material-ui/core";
import Section from "../components/organisms/Section";
import SectionHeader from "../components/molecules/SectionHeader";
import LearnMoreLink from "../components/atoms/LearnMoreLink";

import { makeStyles } from "@material-ui/core";
import Field from "../components/atoms/Field";

//import SpinnerLoader from "../components/SpinnerLoader/SpinnerLoader";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const LoginPage = ({ history }) => {
  const classes = useStyles();

  const { setIsAuthenticated } = useContext(AuthContext);
  const { setRole } = useContext(AuthContext);
  const { setUserName } = useContext(AuthContext);
  const { setLoading } = useContext(AuthContext);
  const { setYears } = useContext(AuthContext);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");

  //Gestion des champs
  const handleChange = ({ currentTarget }) => {
    const value = currentTarget.value;
    const name = currentTarget.name;

    setCredentials({ ...credentials, [name]: value });
  };

  //Gestion du submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await authAPI.authenticate(credentials);

      const ask = await authAPI.getRole();
      setRole(ask);

      const id = await authAPI.getIdentity();
      setUserName(id);

      setError("");
      setIsAuthenticated(true);
      setLoading(false);
      history.replace("/front");
    } catch (error) {
      setError(
        "Les informations ne correspondent pas ou l'email n'a pas été validé"
      );
      setLoading(false);
    }
  };

  return (
    <>
      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title="Se connecter"
            subtitle={
              <span>
                Pas encore inscrit?{" "}
                <Link to="/register">
                  <LearnMoreLink
                    title="S'enregistrer."
                    typographyProps={{ variant: "h6" }}
                  />
                </Link>
              </span>
            }
            titleProps={{
              variant: "h3",
            }}
          />

          <div className={classes.root}>
            <form
              name="password-reset-form"
              method="post"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    placeholder=" Votre Email"
                    label="Email *"
                    name="username"
                    onChange={handleChange}
                    type="email"
                    value={credentials.username}
                    error={error ? true : false}
                    helperText={error}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    placeholder="Votre mot de passe"
                    label="Mot de passe *"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={credentials.password}
                    error={error.password ? true : false}
                    helperText={error.password}
                  />
                </Grid>

                <Grid item xs={12}>
                  <i>
                    <Typography variant="subtitle2">
                      Les champs marqués d'une * sont obligatoires.
                    </Typography>
                  </i>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    Se connecter
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Link to="forgottenPassword">
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="center"
                    >
                      Mot de passe oublié?{" "}
                      <LearnMoreLink title="Réinitialiser." />
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Section>
    </>
  );
};

export default LoginPage;
