import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarsIcon from '@material-ui/icons/Stars';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { NavLink } from 'react-router-dom';


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        size="small"
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);





const Dropdown = ({ buttonText, firstOption = null, firstPath = "", secondOption = null, secondPath = "", thirdOption = null, thirdPath = "" }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const mediumSize = useMediaQuery('(min-width:1320px)');

    return (
        <div>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                size={mediumSize ? "medium" : "small"}
            >
                {buttonText}
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                {firstOption &&
                    <NavLink to={firstPath} style={{ color: "black", textDecoration: 'none' }} onClick={handleClose}>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <AddIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={firstOption} />
                        </StyledMenuItem>
                    </NavLink>
                }

                {secondOption &&
                    <NavLink to={secondPath} style={{ color: "black", textDecoration: 'none' }} onClick={handleClose}>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <ListIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={secondOption} />
                        </StyledMenuItem>
                    </NavLink>
                }

                {thirdOption &&
                    <NavLink to={thirdPath} style={{ color: "black", textDecoration: 'none' }} onClick={handleClose}>
                        <StyledMenuItem>
                            <ListItemIcon>
                                <StarsIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={thirdOption} />
                        </StyledMenuItem>
                    </NavLink>
                }

            </StyledMenu>
        </div>
    );
}

export default Dropdown;